


























import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Index extends Vue {

  created() {
    console.log("aboutUs created");
  }
}
